<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav
      v-if="userData.role !== 'admin'"
      class="nav"
    >
      <b-nav-item-dropdown
        class="dropdown-user nomarker w-auto"
        menu-class="w-100"
        left
        toggle-class="d-flex align-items-center dropdown-user-link"
      >
        <template #button-content>
          <b-button
            variant="primary"
            @click="open=!open"
          >
            <div class="d-flex text-left">
              <div class="mr-1">
                <feather-icon
                  icon="UserIcon"
                  size="22"
                />
              </div>
              <div>
                <span class="font-small-1 mb-25">Viewing As:</span>
                <h6
                  style="color:#fff; font-size: 10px; margin-bottom:0px; text-align:left;"
                  class="d-flex justify-content-between"
                >
                  <span>{{ selectedStartup.title }}</span>
                  <feather-icon
                    :icon="open?'ChevronUpIcon':'ChevronDownIcon'"
                  />
                </h6>
              </div>
            </div>
          </b-button>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="selectedStartup = {
            id: globalstartups[0].id,
            title: globalstartups[0].full_name,
          }; updateSelectedStartup([])"
        >
          <span><b-badge
            variant="light-primary"
            v-text="`Self`"
          /> {{ globalstartups[0].full_name }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-for="startups in globalstartups[0].users_associationtables"
          :key="startups.id"
          link-class="text-wrap"
          @click="selectedStartup = startups.users_organizationtable; updateSelectedStartup([startups])"
        >
          <span><b-badge
            variant="light-primary"
            v-text="userData.role === 'user' ? 'Startup' : 'Partner'"
          /> {{ startups.users_organizationtable.title }}</span>

        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <!--      <bookmarks />-->
      <tour />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <locale />-->
      <!-- <b-nav-item
        target="_blank"
        href="https://forms.gle/rrceubX8tnGJKQGh6"
      >
        <feather-icon
          size="21"
          icon="LifeBuoyIcon"
        />
        Contact Us
      </b-nav-item> -->
      <!--      <search-bar />-->
      <!--      <cart-dropdown />-->

      <timezone-dropdown />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton, BNavItemDropdown, BDropdownItem,
  // BNavItem,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'

// import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
import Tour from '@/views/extensions/tour/Tour.vue'
// import SearchBar from './components/SearchBar.vue'
// import DarkToggler from './components/DarkToggler.vue'
// import CartDropdown from './components/CartDropdown.vue'

import TimezoneDropdown from '@core/layouts/components/app-navbar/components/TimezoneDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    Tour,
    BButton,
    // BNavItem,
    // Bookmarks,
    // Locale,
    // SearchBar,
    // DarkToggler,
    // CartDropdown,
    NotificationDropdown,
    UserDropdown,
    TimezoneDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      open: false,

      savedUserData: getUserData(),
      selectedStartup: {
        id: 0,
        title: '',
      },
      globalselectStartup: 0,
      globalstartups: [],
    }
  },
  methods: {
    assignGlobal(strtid) {
      if (this.userData.role !== 'admin') {
        if (this.userData.associatedOrgDetails && this.userData.associatedOrgDetails.length > 1) {
          this.userData.associatedOrgDetails.map(strtup => {
            if (strtup.organization_id === strtid) {
              localStorage.setItem('userData', JSON.stringify({
                ...this.userData,
                associatedOrgDetails: [strtup],
              }))
              window.location.reload()
            }
            return 0
          })
        } else {
          this.selectedStartup = this.userData.associatedOrgDetails.length > 0 ? this.userData.associatedOrgDetails[0].users_organizationtable : {
            id: this.userData.id,
            title: this.userData.fullName,
          }
        }
      }
    },
    updateSelectedStartup(startupdetails) {
      const oldStartupDetails = getUserData().associatedOrgDetails
      if (JSON.stringify(startupdetails) === JSON.stringify(oldStartupDetails)) return false
      if (startupdetails === oldStartupDetails) return false
      localStorage.setItem('userData', JSON.stringify({
        ...this.userData,
        associatedOrgDetails: startupdetails,
      }))
      this.$router.go()
      return true
    },

  },
  apollo: {
    globalstartups: {
      query() {
        return gql`
        {
          users_customuser(where: {email: {_eq: "${this.userData.userEmail}"}}) {
            id
            is_student
            is_mentor
            full_name
            users_organizationtable {
              id
              title
            }
            users_associationtables(where: {role: {_in: ["startup","partner"]}, organization_id: {_is_null: false}}) {
              id
              organization_id
              program_id
              role
              designation
              users_organizationtable {
                id
                title
              }
            }
          }
        }`
      },
      update(data) {
        if (this.userData.role !== 'admin') {
          if ((data.users_customuser[0].is_student && this.userData.role === 'user') || (data.users_customuser[0].is_mentor && this.userData.role === 'mentor')) {
            this.selectedStartup = {
              id: data.users_customuser[0].id,
              title: data.users_customuser[0].full_name,
            }
            this.updateSelectedStartup([])
          } else {
            this.selectedStartup = {
              id: data.users_customuser[0].users_organizationtable ? data.users_customuser[0].users_organizationtable.id : data.users_customuser[0].id,
              title: data.users_customuser[0].users_organizationtable ? data.users_customuser[0].users_organizationtable.title : data.users_customuser[0].full_name,
            }
            this.assignGlobal(data.users_customuser[0].users_organizationtable ? data.users_customuser[0].users_organizationtable.id : data.users_customuser[0].id)
          }
        }
        return data.users_customuser
      },
    },

  },
}
</script>
