<template>
  <b-nav-item-dropdown
    class="dropdown-user nomarker"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    menu-class="w-auto"
  >
    <template #button-content>
      <b-button
        variant="flat-primary"
        class="px-50"
        @click="timeopen=!timeopen"
      >
        <div class="d-flex">
          <div class="mr-50">
            <feather-icon
              icon="ClockIcon"
              size="22"
            />
          </div>
          <div>
            <h6 style="font-size: 10px; margin-bottom:0px; text-align:left;">
              <strong>Timezone:</strong>
            </h6>
            <h6
              style="font-size: 10px; margin-bottom:0px; text-align:left;"
              class="d-flex justify-content-between"
            >
              <span style="margin-right:1ch">{{ savedUserData.timezone.text.split(" ")[0] }}</span>
              <span v-if="timeopen">{{ savedUserData.timezone.text.replace(savedUserData.timezone.text.split(" ")[0], "") }}</span>
              <feather-icon
                :icon="timeopen?'ChevronUpIcon':'ChevronDownIcon'"
              />
            </h6>
          </div>
        </div>
      </b-button>
    </template>

    <div class="custom-search px-1 pt-50">
      <b-form-group
        class="mb-0"
      >
        <b-form-input
          v-model="timesearch"
          style="pointer-events:all; width:100%"
          autofocus
          class="d-block"
          placeholder="Search Time Zone"
          type="text"
        />
      </b-form-group>
    </div>
    <vue-perfect-scrollbar
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-dropdown-item
        v-for="(timezone, key) in filteredTimezones"
        :key="key"
        link-class="d-flex align-items-center"
        @click="updateTimeZone(timezone)"
      >
        <span>{{ timezone.text }}</span>
      </b-dropdown-item>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BFormGroup, BDropdownItem, BFormInput,
} from 'bootstrap-vue'
// import Timezones from '@/views/pages/account-setting/timezones.json'
import moment from 'moment-timezone'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar/index'

export default {
  components: {
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BNavItemDropdown,
    VuePerfectScrollbar,
  },
  directives: { Ripple },
  data() {
    return {
      timeopen: false,
      timesearch: '',
      savedUserData: getUserData(),
      timezones: moment.tz.names().map(tz => ({
        abbr: moment.tz(tz).format('z'),
        text: `(UTC${moment.tz(tz).format('Z')}) ${tz.replace('_', ' ')}`,
        short: moment.tz(tz).format('z'),
        utc: [tz],
      })),
    }
  },
  computed: {
    filteredTimezones() {
      let filtered = this.timezones
      if (this.timesearch !== '') {
        filtered = this.timezones.filter(tz => tz.text.toLowerCase().includes(this.timesearch.toLowerCase()))
      }
      return filtered
    },
  },
  methods: {
    updateTimeZone(tzone) {
      localStorage.setItem('userData', JSON.stringify({
        ...getUserData(),
        timezone: {
          abbr: tzone.abbr,
          text: tzone.text,
        },
      }))
      this.$router.go()
    },
  },

}
</script>
